import actions from '../actions/compositions';
import { createReducer } from '@reduxjs/toolkit';
import { arrayMove } from '../../util/general';
import { generateUUID } from '../../util/story';

const initialState: any = {};

const compositions = createReducer(initialState, {
    [actions.SET_COMPOSITION]: (state, action: any) => {
        state[action.id] = action.config;
    },

    [actions.EDIT_COMPOSITION]: (state, action: any) => {
        state[action.id] = action.config;
    },

    [actions.UPDATE_LAYER]: (state, action: any) => {
        const layers = [...state[action.id].layers];
        const index = layers.findIndex((l) => l.id === action.config.id);
        state[action.id].layers[index] = action.config;
    },

    [actions.SHIFT_LAYERS]: (state, action: any) => {
        const newLayers = [...state[action.id].layers];

        for (const layerId of action.layerIds) {
            const index = newLayers.findIndex((l) => l.id === layerId);
            const layer = newLayers[index];
            newLayers[index].start_frame = layer.start_frame + action.frames;
            newLayers[index].end_frame = layer.end_frame + action.frames;
        }
        state[action.id].layers = newLayers;
    },

    [actions.UPDATE_ANCHOR]: (state, action: any) => {
        const index = state[action.id].layers.findIndex((l) => l.id === action.layerId);
        const layerAnchorKey = action.layerStart ? 'anchor_start' : 'anchor_end';
        const targetAnchorKey = action.targetStart ? 'start_frame' : 'end_frame';

        state[action.id].layers[index][layerAnchorKey] = {
            id: action.targetId,
            attachment_point: targetAnchorKey
        };
    },

    [actions.DELETE_ANCHOR]: (state, action: any) => {
        const index = state[action.id].layers.findIndex((l) => l.id === action.layerId);
        let layerAnchorKey;
        if (action.anchorLayerId) {
            const layer = state[action.id].layers[index];
            layerAnchorKey =
                layer.anchor_start &&
                layer.anchor_start.id === action.anchorLayerId &&
                ((layer.anchor_start.attachment_point === 'start_frame' && action.start) ||
                    (layer.anchor_start.attachment_point === 'end_frame' && !action.start))
                    ? 'anchor_start'
                    : 'anchor_end';
        } else {
            layerAnchorKey = action.start ? 'anchor_start' : 'anchor_end';
        }
        state[action.id].layers[index][layerAnchorKey] = null;
    },

    [actions.ADD_CUT]: (state, action: any) => {
        state[action.id].cuts.push(action.config.cut);
    },

    [actions.ADD_LAYER]: (state, action: any) => {
        state[action.id].layers.push(action.config);
        if (action.index !== undefined && action.index !== null) {
            let newLayers = [...state[action.id].layers];
            newLayers = arrayMove(newLayers, newLayers.length - 1, action.index);
            state[action.id].layers = newLayers;
        }
    },

    [actions.DELETE_CUT]: (state, action: any) => {
        const newCuts = [...state[action.id].cuts];
        newCuts.splice(action.config.cutIndex, 1);
        state[action.id].cuts = newCuts;
    },

    [actions.DELETE_LAYER]: (state, action: any) => {
        const newLayers = [...state[action.id].layers];

        // Remove the layer from the layers
        const index = newLayers.findIndex((l) => l.id === action.layerId);
        newLayers.splice(index, 1);

        // Remove any anchors and mattes which were attached to the layer we're deleting
        for (const layer of newLayers) {
            const { anchor_start, anchor_end, matte_layer_id } = layer;
            if (anchor_start && anchor_start.id === action.layerId) {
                delete layer.anchor_start;
            }
            if (anchor_end && anchor_end.id === action.layerId) {
                delete layer.anchor_end;
            }
            if (matte_layer_id === action.layerId) {
                layer.matte_layer_id = null;
            }
        }

        state[action.id].layers = newLayers;
    },

    [actions.SET_LAYER_MATTE]: (state, action: any) => {
        const newLayers = [...state[action.compId].layers];
        for (let i = 0; i < newLayers.length; i++) {
            const newLayer = { ...newLayers[i] };
            const newEffects = { ...newLayer.effects };
            if (newLayer.id === action.layerId) {
                newLayer.matte_layer_id = action.matteLayerId;
                newLayer.options.invert_layer_matte = false;
            } else if (newLayer.id === action.matteLayerId) {
                newLayer.audio_enabled = false;
                newLayer.matte_layer_id = null;
                newEffects.blend_mode = '';
                newLayer.effects = newEffects;
            }
            newLayers[i] = newLayer;
        }

        state[action.compId].layers = newLayers;
    },

    [actions.DELETE_LAYERS]: (state, action: any) => {
        const newLayers = [...state[action.id].layers];

        for (const layerId of action.layerIds) {
            const index = newLayers.findIndex((l) => l.id === layerId);
            newLayers.splice(index, 1);

            // Remove any anchors which were attached to the layer we're deleting
            for (const layer of newLayers) {
                const { anchor_start, anchor_end } = layer;
                if (anchor_start && anchor_start.id === layerId) {
                    delete layer.anchor_start;
                }
                if (anchor_end && anchor_end.id === layerId) {
                    delete layer.anchor_end;
                }
            }
        }
        state[action.id].layers = newLayers;
    },

    [actions.DUPLICATE_LAYER]: (state, action: any) => {
        const layers = [...state[action.id].layers];
        const index = layers.findIndex((l) => l.id === action.layerId);
        const duplicatedLayer = { ...layers[index] };

        duplicatedLayer.id = action.newId;
        duplicatedLayer.name = `Copy of ${duplicatedLayer.name}`;
        duplicatedLayer.keyframes = getSanitizedKeyframes(duplicatedLayer.keyframes);

        state[action.id].layers.push(duplicatedLayer);

        if (action.index !== undefined && action.index !== null) {
            let newLayers = [...state[action.id].layers];
            newLayers = arrayMove(newLayers, newLayers.length - 1, action.index);
            state[action.id].layers = newLayers;
        }
    },

    [actions.DUPLICATE_LAYERS]: (state, action: any) => {
        const layers = [...state[action.id].layers];
        const layerIds: string[] = action.layerIds;
        const newIds: string[] = action.newIds;

        for (const layerId of layerIds) {
            const index = layerIds.findIndex((l) => l === layerId);
            const layerIndex = layers.findIndex((l) => l.id === layerId);
            const duplicatedLayer = { ...layers[layerIndex] };

            duplicatedLayer.id = newIds[index];
            duplicatedLayer.name = `Copy of ${duplicatedLayer.name}`;
            duplicatedLayer.keyframes = getSanitizedKeyframes(duplicatedLayer.keyframes);

            state[action.id].layers.push(duplicatedLayer);

            if (action.index !== undefined && action.index !== null) {
                let newLayers = [...state[action.id].layers];
                newLayers = arrayMove(newLayers, newLayers.length - 1, action.index + 1);
                state[action.id].layers = newLayers;
            }
        }
    },

    [actions.MOVE_LAYERS]: (state, action: any) => {
        const { compId, layers: layersToMove, index } = action;
        const compLayers = state[compId].layers;

        const beforeIndex = [];
        const movedLayers = [];
        const afterIndex = [];

        for (let i = 0; i < compLayers.length; i++) {
            const layer = { ...compLayers[i] };
            if (layersToMove.indexOf(layer.id) !== -1) {
                movedLayers.push(layer);
            } else if (i < index) {
                beforeIndex.push(layer);
            } else if (i >= index) {
                afterIndex.push(layer);
            }
        }

        const combined = [beforeIndex, movedLayers, afterIndex].flat();
        state[compId].layers = combined;
    }
});

const getSanitizedKeyframes = (keyframes) => {
    if (keyframes) {
        const newKeyframes = {};
        for (const key in keyframes) {
            if (keyframes.hasOwnProperty(key)) {
                if (keyframes[key].length > 0) {
                    const newArray = [];
                    for (const keyframe of keyframes[key]) {
                        const k = {
                            id: generateUUID(),
                            type: keyframe.type,
                            relativeFrame: keyframe.relativeFrame,
                            value: keyframe.value,
                            ease: keyframe.ease
                        };
                        newArray.push(k);
                    }
                    newKeyframes[key] = newArray;
                }
            }
        }
        return newKeyframes;
    } else {
        return {};
    }
};

export default compositions;
