import changes from './changes';
import { api } from '../../constants/app';
import { generateUUID } from '../../util/story';
import { ActionCreators } from 'redux-undo';
import { log } from './notifications';
import { NOTIFICATION_TYPES } from '../../constants/story';
import timeline from './timeline';
import { getDupArrayAndIndexs, getDupLayerIndex } from '../../util/composition';
import { EMPTY_TIMELINE_STATE } from '../../constants/snippets';

const compositions: any = {
    EDIT_COMPOSITION: 'compositions/EDIT_COMPOSITION',
    SET_COMPOSITION: 'compositions/SET_COMPOSITION',
    UPDATE_LAYER: 'compositions/UPDATE_LAYER',
    UPDATE_ANCHOR: 'compositions/UPDATE_ANCHOR',
    DELETE_ANCHOR: 'compositions/DELETE_ANCHOR',
    MOVE_LAYERS: 'compositions/REORDER_LAYERS',
    DELETE_LAYER: 'compositions/DELETE_LAYER',
    DELETE_LAYERS: 'compositions/DELETE_LAYERS',
    DUPLICATE_LAYER: 'compositions/DUPLICATE_LAYER',
    DUPLICATE_LAYERS: 'compositions/DUPLICATE_LAYERS',
    ADD_LAYER: 'compositions/ADD_LAYER',
    SHIFT_LAYERS: 'compositions/SHIFT_LAYERS',
    SET_LAYER_MATTE: 'compositions/SET_LAYER_MATTE'
};

export const loadComposition = (id: string): any => {
    return (dispatch, getState) => {
        return new Promise<void>((resolve, reject) => {
            const { timeline: timelineState } = getState();

            api.getAssetItem(id)
                .then((res: any) => {
                    // If there is no empty state object for this comp / timeline, create it
                    if (!timelineState[id]) {
                        const newState = { ...EMPTY_TIMELINE_STATE };
                        dispatch({ type: timeline.UPDATE, config: newState, id });
                    }

                    dispatch({
                        type: compositions.SET_COMPOSITION,
                        id,
                        config: JSON.parse(res.data)
                    });

                    dispatch(ActionCreators.clearHistory());
                    resolve();
                })
                .catch((e) => {
                    reject(e);
                });
        });
    };
};

export const createComposition = (storyId, data, name?): any => {
    return (dispatch, getState) => {
        return new Promise<void>((resolve, reject) => {
            api.createCompositionAsset(storyId, JSON.stringify(data), name)
                .then((res: any) => {
                    const asset: any = res[0];
                    const timelineState = { ...EMPTY_TIMELINE_STATE };
                    dispatch({ type: timeline.UPDATE, config: timelineState, id: asset.id });

                    dispatch({
                        type: compositions.SET_COMPOSITION,
                        id: asset.id,
                        config: JSON.parse(asset.data)
                    });

                    dispatch(ActionCreators.clearHistory());
                    resolve(asset);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    };
};

export const setComposition = (id, data): any => {
    return (dispatch, getState) => {
        const { timeline: timelineState } = getState();

        // If there is no empty state object for this comp / timeline, create it
        if (!timelineState[id]) {
            const newState = { ...EMPTY_TIMELINE_STATE };
            dispatch({ type: timeline.UPDATE, config: newState, id });
        }

        dispatch({ type: compositions.SET_COMPOSITION, id, config: data });

        dispatch(ActionCreators.clearHistory());
    };
};

export const editComposition = (id, data): any => {
    return (dispatch) => {
        dispatch({ type: compositions.EDIT_COMPOSITION, id, config: data });
        dispatch({ type: changes.UPDATE_COMP, id });
    };
};

export const updateLayer = (id, config): any => {
    return (dispatch) => {
        dispatch({ type: compositions.UPDATE_LAYER, id, config });
        dispatch({ type: changes.UPDATE_COMP, id });
    };
};

export const updateAnchor = (compId, layerId, targetId, layerStart, targetStart): any => {
    return (dispatch, getState) => {
        const layers = getState().compositions.present[compId].layers;
        const attachedLayers = getAnchoredLayers([], layers, targetId);

        if (attachedLayers.indexOf(layerId) !== -1) {
            dispatch(
                log('Recursive anchor loop detected, anchor not added.', NOTIFICATION_TYPES.ERROR)
            );
        } else {
            dispatch({
                type: compositions.UPDATE_ANCHOR,
                id: compId,
                layerId,
                targetId,
                layerStart,
                targetStart
            });
            dispatch({ type: changes.UPDATE_COMP, id: compId });
        }
    };
};

const getAnchoredLayers = (attached, layers, targetId): any => {
    // get any layers attached to this layer via its start and end anchors
    const targetLayer = layers.find((i) => i.id === targetId);

    if (targetLayer) {
        if (targetLayer.anchor_start) {
            const startId = targetLayer.anchor_start.id;
            if (attached.indexOf(startId) === -1) {
                attached.push(startId);
                attached.concat(getAnchoredLayers(attached, layers, startId));
            }
        }

        if (targetLayer.anchor_end) {
            const endId = targetLayer.anchor_end.id;
            if (attached.indexOf(endId) === -1) {
                attached.push(endId);
                attached.concat(getAnchoredLayers(attached, layers, endId));
            }
        }
    }

    return attached;
};

export const deleteAnchor = (compId, layerId, start, anchorLayerId): any => {
    return (dispatch) => {
        dispatch({ type: compositions.DELETE_ANCHOR, id: compId, layerId, anchorLayerId, start });
        dispatch({ type: changes.UPDATE_COMP, id: compId });
    };
};

export const addLayer = (id, config, index?): any => {
    return (dispatch) => {
        dispatch({ type: compositions.ADD_LAYER, id, config, index });
        dispatch({ type: changes.UPDATE_COMP, id });
    };
};

export const deleteLayer = (id, layerId): any => {
    return (dispatch) => {
        dispatch({ type: timeline.UPDATE, config: { activeTimelineLayer: null } });
        dispatch({ type: compositions.DELETE_LAYER, id, layerId });
        dispatch({ type: changes.UPDATE_COMP, id });
    };
};

export const deleteLayers = (id, layerIds): any => {
    return (dispatch) => {
        dispatch({
            type: timeline.UPDATE,
            config: { activeTimelineLayer: null, activeMultiSelectLayers: [] }
        });
        dispatch({ type: compositions.DELETE_LAYERS, id, layerIds });
        dispatch({ type: changes.UPDATE_COMP, id });
    };
};

export const shiftLayers = (id, layerIds, frames): any => {
    return (dispatch) => {
        dispatch({ type: compositions.SHIFT_LAYERS, id, layerIds, frames });
        dispatch({ type: changes.UPDATE_COMP, id });
    };
};

export const duplicateLayer = (id, layerId): any => {
    return (dispatch) => {
        const index = getDupLayerIndex() + 1;
        const newId = generateUUID();
        dispatch({ type: timeline.UPDATE, config: { activeTimelineLayer: null } });
        dispatch({ type: compositions.DUPLICATE_LAYER, id, layerId, newId, index });
        dispatch({ type: changes.UPDATE_COMP, id });
        dispatch({ type: timeline.UPDATE, config: { activeTimelineLayer: newId } });
    };
};

export const duplicateLayers = (id): any => {
    return (dispatch) => {
        const newIds = [];
        const { dupArray, indexs } = getDupArrayAndIndexs();
        const layerIds = dupArray.map((s) => s.id);
        const index = Math.max(...indexs);

        for (const i of layerIds) {
            if (i) {
                const newId = generateUUID();
                newIds.push(newId);
            }
        }

        dispatch({ type: timeline.UPDATE, config: { activeMultiSelectLayers: [] } });
        dispatch({ type: compositions.DUPLICATE_LAYERS, id, layerIds, newIds, index });
        dispatch({ type: changes.UPDATE_COMP, id });
        dispatch({ type: timeline.UPDATE, config: { activeMultiSelectLayers: newIds } });
    };
};

export const moveLayers = (compId, layers, index): any => {
    return (dispatch) => {
        dispatch({ type: compositions.MOVE_LAYERS, compId, layers, index });
        dispatch({ type: changes.UPDATE_COMP, compId });
    };
};

export const setLayerMatte = (cId, lId, mId): any => {
    return (dispatch) => {
        dispatch({
            type: compositions.SET_LAYER_MATTE,
            compId: cId,
            layerId: lId,
            matteLayerId: mId
        });
        dispatch({ type: changes.UPDATE_COMP, id: cId });
    };
};

export default compositions;
