import editor from './editor';
import changes from './changes';
import errors from './errors';
import { log } from './notifications';
import {
    storyAdded,
    resetFilters,
    storyNameMutated,
    storyDeleted,
    getStoryPublishStatus
} from '@imposium-hub/components';
import { api } from '../../constants/app';
import { IStory } from '../../constants/snippets';
import { redux, storyErrors } from '../../constants/copy';
import { STORY_ERRORS } from '../../constants/errors';
import { COMPOSITION_LAYER_TYPES, NOTIFICATION_TYPES } from '../../constants/story';
import { doPollJobCompletionStatus } from './batch-jobs';
import timeline from './timeline';
import { getFirstAct } from '../../util/story';

const story: any = {
    ADD_SCENE: 'story/ADD_SCENE',
    DELETE_SCENE: 'story/DELETE_SCENE',
    UPDATE_CUT: 'story/UPDATE_CUT',
    UPDATE_SCENE: 'story/UPDATE_SCENE',
    UPDATE_INVENTORY: 'story/UPDATE_INVENTORY',
    UPDATE_MULTIPLE_INVENTORY: 'story/UPDATE_MULTIPLE_INVENTORY',
    CHANGE_INVENTORY_ID: 'story/CHANGE_INVENTORY_ID',
    EDIT_STORY: 'story/EDIT_STORY',
    SET_STORY: 'story/SET_STORY',
    REORDER_CUTS: 'story/REORDER_CUTS',
    DELETE_STORY: 'story/DELETE_STORY',
    DELETE_INVENTORY: 'story/DELETE_INVENTORY',
    DELETE_CUT: 'story/DELETE_CUT',
    ADD_CUT: 'story/ADD_CUT',
    ADD_AUDIO_OVERLAY: 'story/ADD_AUDIO_OVERLAY',
    DELETE_AUDIO_OVERLAY: 'story/DELETE_AUDIO_OVERLAY',
    UPDATE_AUDIO_OVERLAY: 'story/UPDATE_AUDIO_OVERLAY',
    DUPLICATE_AUDIO_OVERLAY: 'story/DUPLICATE_AUDIO_OVERLAY',
    FLUSH_STORY: 'story/FLUSH_STORY',
    ADD_VIEWER: 'viewer/ADD',
    UPDATE_VIEWER: 'viewer/UPDATE',
    REPLACE_VIEWER: 'viewer/REPLACE',
    CLOSE_VIEWER: 'viewer/CLOSE',
    SET_ACTIVE: 'viewer/SET_ACTIVE'
};

export interface IAssetViewConfig {
    type: string;
    id: string;
    label: string;
    asset: any;
}

interface ICutViewer {
    type: string;
    id: string;
    label: string;
    jobId: string;
}

export const overwriteStory = (id: string, newStoryData: IStory): any => {
    return (dispatch, getStore) => {
        const {
            access,
            routing: {
                locationBeforeTransitions: { query }
            }
        } = getStore();
        const currentOrg = access.organizations.find((o: any) => o.id === query.organization_id);

        let currentStory: any;

        if (currentOrg) {
            currentStory = currentOrg.stories.find((s: any) => s.id === id);
        }

        dispatch({ type: changes.UPDATE, config: { unsaved: false } });

        return new Promise<void>((resolve, reject) => {
            api.saveStory(id, newStoryData)
                .then((data: any) => {
                    if (currentStory && currentStory.name !== data.name) {
                        dispatch(storyNameMutated(id, data.name));
                    }
                    dispatch({ type: story.SET_STORY, data: newStoryData });
                    dispatch(log(redux.saveStorySuccess, NOTIFICATION_TYPES.INFO));
                    resolve();
                })
                .catch((e) => {
                    dispatch(log(storyErrors.saveStoryError, NOTIFICATION_TYPES.ERROR));
                    reject(e);
                });
        });
    };
};

export const getStory = (id: string): any => {
    return (dispatch) => {
        return new Promise<void>((resolve, reject) => {
            api.getStory(id)
                .then((data: any) => {
                    dispatch({ type: story.SET_STORY, data });
                    dispatch(checkStoryForErrors(false));
                    resolve();
                })
                .catch((e) => {
                    reject(e);
                });
        });
    };
};

export const checkStoryForErrors = (includeLog: boolean = true): any => {
    return async (dispatch, getStore) => {
        const store = getStore();
        const {
            project: { actId, sceneId }
        } = store;
        const act = actId ? store.story.acts[actId] : getFirstAct(store.story);
        const scene = act.scenes[sceneId];
        const cutErrors = findCutErrors(scene.sceneData.cuts);
        const overlayErrors = await findOverlayErrors(scene.sceneData.cuts);
        const audioErrors = await findAudioErrors(scene.sceneData.audioOverlays);

        const errorList = [];

        if (overlayErrors && overlayErrors.length > 0) {
            dispatch({ type: errors.OVERLAY_ERROR, overlayErrors });
            if (includeLog) {
                dispatch(log(storyErrors.foundErrors, NOTIFICATION_TYPES.WARNING));
            }
            errorList.push(overlayErrors);
        } else {
            dispatch({ type: errors.CLEAR_OVERLAY_ERROR });
        }

        if (cutErrors && cutErrors.length > 0) {
            dispatch({ type: errors.CUT_ERROR, cutErrors });
            if (includeLog) {
                dispatch(log(storyErrors.foundErrors, NOTIFICATION_TYPES.WARNING));
            }
            errorList.push(cutErrors);
        } else {
            dispatch({ type: errors.CLEAR_CUT_ERROR });
        }

        if (audioErrors && audioErrors.length > 0) {
            dispatch({ type: errors.AUDIO_ERROR, audioErrors });
            if (includeLog) {
                dispatch(log(storyErrors.foundErrors, NOTIFICATION_TYPES.WARNING));
            }
            errorList.push(audioErrors);
        } else {
            dispatch({ type: errors.CLEAR_AUDIO_ERROR });
        }
        return errorList;
    };
};

export const saveStory = (): any => {
    return async (dispatch, getStore) => {
        const store = getStore();
        const {
            access,
            routing: {
                locationBeforeTransitions: { query }
            },
            story: { acts, id: storyId },
            project: { actId, sceneId }
        } = store;
        const currentOrg = access.organizations.find((o: any) => o.id === query.organization_id);
        const scene = acts[actId].scenes[sceneId];

        let currentStory: any;

        if (currentOrg) {
            currentStory = currentOrg.stories.find((s: any) => s.id === store.story.id);
        }

        const cutErrors = findCutErrors(scene.sceneData.cuts);
        const overlayErrors = await findOverlayErrors(scene.sceneData.cuts);
        const audioErrors = await findAudioErrors(scene.sceneData.audioOverlays);

        if (overlayErrors && overlayErrors.length > 0) {
            dispatch({ type: errors.OVERLAY_ERROR, overlayErrors });
        } else {
            dispatch({ type: errors.CLEAR_OVERLAY_ERROR });
        }

        if (cutErrors && cutErrors.length > 0) {
            dispatch({ type: errors.CUT_ERROR, cutErrors });
        } else {
            dispatch({ type: errors.CLEAR_CUT_ERROR });
        }

        if (audioErrors && audioErrors.length > 0) {
            dispatch({ type: errors.AUDIO_ERROR, audioErrors });
        } else {
            dispatch({ type: errors.CLEAR_AUDIO_ERROR });
        }

        dispatch({ type: changes.UPDATE, config: { unsaved: false, loading: true } });
        return new Promise<void>((resolve, reject) => {
            api.saveStory(store.story.id, store.story)
                .then((data: any) => {
                    if (currentStory && currentStory.name !== data.name) {
                        dispatch(storyNameMutated(store.story.id, data.name));
                    }

                    if (cutErrors?.length > 0 || overlayErrors?.length > 0) {
                        dispatch(log(storyErrors.saveStoryWithError, NOTIFICATION_TYPES.WARNING));
                    } else {
                        dispatch(log(redux.saveStorySuccess, NOTIFICATION_TYPES.INFO));
                    }
                    // if there are any unsaved comps for this story, save the assets
                    for (const compId of store.changes.comps) {
                        const compData = store.compositions.present[compId];
                        if (compData) {
                            const dataStr = JSON.stringify(compData);
                            api.updateAsset(compId, { data: dataStr });
                        }
                    }

                    // if there are any unsaved templates for this story, save the assets
                    for (const assetId of store.changes.templates) {
                        const compData = store.templates.present[assetId];
                        if (compData) {
                            const dataStr = JSON.stringify(compData);
                            api.updateAsset(assetId, { data: dataStr });
                        }
                    }

                    dispatch({
                        type: changes.UPDATE,
                        config: { unsaved: false, loading: false, templates: [], comps: [] }
                    });
                    dispatch(getStoryPublishStatus(api, storyId));

                    resolve();
                })
                .catch((e) => {
                    dispatch(log(storyErrors.saveStoryError, NOTIFICATION_TYPES.ERROR));
                    reject(e);
                });
        });
    };
};

export const createStory = (s: IStory): any => {
    return (dispatch, getStore) => {
        return new Promise<void>((resolve, reject) => {
            api.newStory(s)
                .then((data: any) => {
                    dispatch(storyAdded(data.id, data.name));
                    dispatch({ type: story.SET_STORY, data: s });
                    dispatch({ type: changes.UPDATE, config: { unsaved: false } });
                    dispatch(log(redux.createStorySuccess, NOTIFICATION_TYPES.INFO));
                    resolve();
                })
                .catch((e) => {
                    dispatch(
                        log(`${storyErrors.createStoryError} : {e.error}`, NOTIFICATION_TYPES.INFO)
                    );
                    reject(e);
                });
        });
    };
};

export const copyStory = (storyId: string, storyName?: string): any => {
    return (dispatch) => {
        return new Promise<void>((resolve, reject) => {
            api.copyStory(storyId, storyName)
                .then((copyData: any) => {
                    const { job_id, story_id } = copyData;
                    if (storyName) {
                        dispatch(
                            log(
                                redux.copyingStory.replace('[storyName]', `${storyName}`),
                                NOTIFICATION_TYPES.WARNING
                            )
                        );
                    }
                    doPollJobCompletionStatus(job_id).then(() => {
                        api.getStory(story_id).then((storyData: any) => {
                            const { id, name } = storyData;
                            const data = { ...storyData };

                            dispatch(storyAdded(id, name));
                            dispatch(resetFilters());

                            dispatch({ type: story.SET_STORY, data });
                            dispatch({ type: changes.UPDATE, config: { unsaved: false } });

                            dispatch(log(redux.createStorySuccess, NOTIFICATION_TYPES.INFO));

                            resolve(copyData);
                        });
                    });
                })
                .catch((e) => {
                    dispatch(
                        log(`${storyErrors.createStoryError} : {e.error}`, NOTIFICATION_TYPES.INFO)
                    );
                    reject(e);
                });
        });
    };
};

export const deleteStory = (storyId: string): any => {
    return (dispatch, getStore) => {
        dispatch({ type: story.DELETE_STORY });

        return new Promise<void>((resolve, reject) => {
            api.deleteStory(storyId)
                .then((data: any) => {
                    dispatch(storyDeleted(storyId));
                    dispatch(log(redux.deleteStorySuccess, NOTIFICATION_TYPES.INFO));
                    resolve();
                })
                .catch((e) => {
                    dispatch(log(storyErrors.deleteStoryError, NOTIFICATION_TYPES.INFO));
                    reject(e);
                });
        });
    };
};

export const clearStoryCache = (storyId: string): any => {
    return (dispatch) => {
        return new Promise<void>((resolve, reject) => {
            api.clearStoryCache(storyId)
                .then(() => {
                    dispatch(log(redux.assetCachedCleared, NOTIFICATION_TYPES.INFO));
                    resolve();
                })
                .catch((e) => {
                    dispatch(log(storyErrors.assetCacheClearError, NOTIFICATION_TYPES.INFO));
                    reject(e);
                });
        });
    };
};

export const editStory = (config): any => {
    return (dispatch) => {
        dispatch({ type: story.EDIT_STORY, config });
        dispatch({ type: changes.UPDATE, config: { unsaved: true } });
    };
};

// TODO: composition splice
export const updateCut = (config): any => {
    return (dispatch) => {
        dispatch({ type: story.UPDATE_CUT, config });
        dispatch({ type: changes.UPDATE, config: { unsaved: true } });
    };
};

// TODO: composition splice
export const addCut = (config): any => {
    return (dispatch) => {
        dispatch({ type: story.ADD_CUT, config });
        dispatch({ type: story.REORDER_CUTS, config });
        dispatch({ type: changes.UPDATE, config: { unsaved: true } });
    };
};

// TODO: composition splice
export const deleteCut = (config): any => {
    return (dispatch) => {
        dispatch({ type: changes.UPDATE, config: { unsaved: true } });
        dispatch({ type: timeline.UPDATE, config: { activeTimelineCut: null } });
        dispatch({ type: story.DELETE_CUT, config });
    };
};

export const addAudioOverlay = (config): any => {
    return (dispatch) => {
        dispatch({ type: story.ADD_AUDIO_OVERLAY, config });
        dispatch({ type: changes.UPDATE, config: { unsaved: true } });
    };
};

// TODO: composition splice
export const updateAudioOverlay = (config): any => {
    return (dispatch, getStore) => {
        const {
            project: { actId, sceneId }
        } = getStore();
        config.actId = actId;
        config.sceneId = sceneId;
        dispatch({ type: changes.UPDATE, config: { unsaved: true } });
        dispatch({ type: story.UPDATE_AUDIO_OVERLAY, config });
    };
};

// TODO: composition splice
export const deleteAudioOverlay = (config): any => {
    return (dispatch, getStore) => {
        const {
            project: { actId, sceneId }
        } = getStore();
        config.actId = actId;
        config.sceneId = sceneId;
        dispatch({ type: changes.UPDATE, config: { unsaved: true } });
        dispatch({ type: timeline.UPDATE, config: { activeTimelineOverlay: null } });
        dispatch({ type: story.DELETE_AUDIO_OVERLAY, config });
    };
};

// TODO: composition splice
export const duplicateAudioOverlay = (config): any => {
    return (dispatch, getStore) => {
        const {
            project: { actId, sceneId }
        } = getStore();
        config.actId = actId;
        config.sceneId = sceneId;
        dispatch({ type: changes.UPDATE, config: { unsaved: true } });
        dispatch({ type: story.DUPLICATE_AUDIO_OVERLAY, config });
    };
};

export const addScene = (config): any => {
    return (dispatch) => {
        dispatch({ type: story.ADD_SCENE, config });
        dispatch({ type: changes.UPDATE, config: { unsaved: true } });
    };
};

export const deleteScene = (config): any => {
    return (dispatch) => {
        dispatch({ type: story.DELETE_SCENE, config });
        dispatch({ type: changes.UPDATE, config: { unsaved: true } });
    };
};

export const updateScene = (config): any => {
    return (dispatch) => {
        dispatch({ type: story.UPDATE_SCENE, config });
        dispatch({ type: changes.UPDATE, config: { unsaved: true } });
    };
};

// TODO: composition splice
export const reorderCuts = (config): any => {
    return (dispatch) => {
        dispatch({ type: story.REORDER_CUTS, config });
        dispatch({ type: changes.UPDATE, config: { unsaved: true } });
    };
};

export const updateInventory = (config): any => {
    return (dispatch) => {
        dispatch({ type: story.UPDATE_INVENTORY, config });
        dispatch({ type: changes.UPDATE, config: { unsaved: true } });
    };
};

export const updateMultipleInventory = (actId, items): any => {
    return (dispatch) => {
        dispatch({ type: story.UPDATE_MULTIPLE_INVENTORY, actId, items });
        dispatch({ type: changes.UPDATE, config: { unsaved: true } });
    };
};

export const deleteInventory = (config): any => {
    return (dispatch) => {
        dispatch({ type: story.DELETE_INVENTORY, config });
        dispatch({ type: changes.UPDATE, config: { unsaved: true } });
    };
};

export const changeInventoryId = (config): any => {
    return (dispatch) => {
        dispatch({ type: story.CHANGE_INVENTORY_ID, config });
        dispatch({ type: changes.UPDATE, config: { unsaved: true } });
    };
};

export const flushStoryState = (): any => ({ type: story.FLUSH_STORY });

export const addViewer = (viewConfig: IAssetViewConfig | ICutViewer): any => {
    return (dispatch) => {
        dispatch({ type: story.ADD_VIEWER, viewConfig });
    };
};

export const updateViewer = (id: string, asset: any): any => {
    return (dispatch) => {
        dispatch({ type: story.UPDATE_VIEWER, id, asset });
    };
};

export const replaceViewer = (viewConfig: IAssetViewConfig | ICutViewer): any => {
    return (dispatch) => {
        if (viewConfig.type === COMPOSITION_LAYER_TYPES.VIDEO_COMPOSITION) {
            const config = { varsDisabled: false };
            dispatch({ type: editor.UPDATE, config });
        }
        dispatch({ type: story.REPLACE_VIEWER, viewConfig });
    };
};

export const setActiveViewer = (id: string): any => {
    return (dispatch, getStore) => {
        const {
            story: { viewer }
        } = getStore();
        const tabs = viewer ? viewer.tabs : [];
        dispatch({ type: story.SET_ACTIVE, id, tabs });
    };
};

export const closeViewer = (id: string): any => {
    return (dispatch) => {
        dispatch({ type: story.CLOSE_VIEWER, id });
    };
};

export const closeOtherViewers = (id: string): any => {
    return (dispatch, getStore) => {
        const {
            story: { viewer }
        } = getStore();
        const tabs = viewer ? viewer.tabs : [];

        for (const tab of tabs) {
            if (tab.id === id) {
                continue;
            }
            dispatch({ type: story.CLOSE_VIEWER, id: tab.id });
            if (tab.type === COMPOSITION_LAYER_TYPES.VIDEO_COMPOSITION) {
                dispatch({ type: timeline.CLEAR, id: tab.id });
            }
        }
    };
};

export const closeRightViewers = (id: string): any => {
    return (dispatch, getStore) => {
        const {
            story: { viewer }
        } = getStore();
        const tabs = viewer ? viewer.tabs : [];

        let closing = false;
        for (const tab of tabs) {
            if (closing) {
                dispatch({ type: story.CLOSE_VIEWER, id: tab.id });
                if (tab.type === COMPOSITION_LAYER_TYPES.VIDEO_COMPOSITION) {
                    dispatch({ type: timeline.CLEAR, id: tab.id });
                }
            }

            if (tab.id === id) {
                closing = true;
            }
        }
    };
};

export const findAudioErrors = async (overlays): Promise<any> => {
    if (overlays) {
        const errorArray = [];

        for (const overlay of overlays) {
            // Audio Asset is selected, but asset doesn't exist
            if (overlay['source']['from'] === 'asset_id' && overlay['source']['asset_id'] === '') {
                errorArray.push({
                    ...errorArrElements(null, overlay['id'], overlay['name'], overlay['source']),
                    error: storyErrors.overlayAssetError,
                    error_id: STORY_ERRORS.OVERLAY_ASSET_ERROR
                });
            }
            // Audio Asset from tags selected, but no tags set
            if (
                overlay['source']['from'] === 'asset_tags' &&
                overlay['source']['asset_tags'].length === 0
            ) {
                errorArray.push({
                    ...errorArrElements(null, overlay['id'], overlay['name'], overlay['source']),
                    error: storyErrors.overlayAssetTagError,
                    error_id: STORY_ERRORS.OVERLAY_ASSET_TAG_ERROR
                });
            }

            if (overlay['source']['asset_id']) {
                await api
                    .getAssetItem(overlay['source']['asset_id'])
                    .then((data: any) => data)
                    .catch((e: Error) => {
                        errorArray.push({
                            ...errorArrElements(
                                null,
                                overlay['id'],
                                overlay['name'],
                                overlay['source']
                            ),
                            error: storyErrors.assetNotFound,
                            error_id: STORY_ERRORS.OVERLAY_ASSET_NOT_FOUND
                        });
                    });
            }
        }

        return errorArray;
    }
};

export const findOverlayErrors = async (cuts): Promise<any> => {
    const errorArray = [];
    if (cuts) {
        for (const cut of cuts) {
            if (cut['overlays']) {
                for (const overlay of cut['overlays']) {
                    // Mask: id is set, but asset doesn't exist
                    if (overlay['effects']) {
                        if (overlay['effects']['mask_asset_id']) {
                            await api
                                .getAssetItem(overlay['effects']['mask_asset_id'])
                                .then((data: any) => data)
                                .catch((e: Error) => {
                                    errorArray.push({
                                        ...errorArrElements(
                                            null,
                                            overlay['id'],
                                            overlay['name'],
                                            overlay['source']
                                        ),
                                        error: storyErrors.overlayMaskError,
                                        error_id: STORY_ERRORS.OVERLAY_EFFECT_MASK_MISSING
                                    });
                                });
                        }
                    }

                    // Asset source selected but no asset_id set
                    if (!overlay['source'] && overlay['generating_script'] === '') {
                        errorArray.push({
                            ...errorArrElements(
                                cut['id'],
                                overlay['id'],
                                overlay['name'],
                                overlay['source']
                            ),
                            error: storyErrors.overlayScriptError,
                            error_id: STORY_ERRORS.OVERLAY_SCRIPT_ERROR
                        });
                    }

                    // Asset is selected, but asset doesn't exist
                    if (overlay['source'] && overlay['source']['asset_id'] === '') {
                        errorArray.push({
                            ...errorArrElements(
                                cut['id'],
                                overlay['id'],
                                overlay['name'],
                                overlay['source']
                            ),
                            error: storyErrors.overlayAssetError,
                            error_id: STORY_ERRORS.OVERLAY_ASSET_ERROR
                        });
                    }

                    // Asset_from_tags source selected, but no tags added
                    if (
                        overlay['source'] &&
                        overlay['source']['from'] === 'asset_tags' &&
                        overlay['source']['asset_tags'].length === 0
                    ) {
                        errorArray.push({
                            ...errorArrElements(
                                cut['id'],
                                overlay['id'],
                                overlay['name'],
                                overlay['source']
                            ),
                            error: storyErrors.overlayAssetTagError,
                            error_id: STORY_ERRORS.OVERLAY_ASSET_TAG_ERROR
                        });
                    }

                    // Variable source selected, but no variable set
                    if (overlay['source'] && overlay['source']['inventory_id'] === '') {
                        errorArray.push({
                            ...errorArrElements(
                                cut['id'],
                                overlay['id'],
                                overlay['name'],
                                overlay['source']
                            ),
                            error: storyErrors.overlayVariableError,
                            error_id: STORY_ERRORS.OVERLAY_VARIABLE_ERROR
                        });
                    }

                    // Position rectangle selected, but coordinates missing
                    if (overlay['position'] === 'StaticRect') {
                        if (
                            overlay['position_inputs']['x'] === null ||
                            undefined ||
                            overlay['position_inputs']['y'] === null ||
                            undefined
                        ) {
                            errorArray.push({
                                ...errorArrElements(
                                    cut['id'],
                                    overlay['id'],
                                    overlay['name'],
                                    overlay['source']
                                ),
                                error: storyErrors.overlayRectError,
                                error_id: STORY_ERRORS.OVERLAY_RECT_ERROR
                            });
                        }
                    }

                    // Position corner pin selected, but coordinates missing
                    if (overlay['position'] === 'StaticQuad') {
                        for (const position in overlay['position_inputs']) {
                            if (
                                overlay['position_inputs'][position]['x'] === null ||
                                undefined ||
                                overlay['position_inputs'][position]['y'] === null ||
                                undefined
                            ) {
                                errorArray.push({
                                    ...errorArrElements(
                                        cut['id'],
                                        overlay['id'],
                                        overlay['name'],
                                        overlay['source']
                                    ),
                                    error: storyErrors.overlayCornerPinError,
                                    error_id: STORY_ERRORS.OVERLAY_CORNER_PIN_ERROR
                                });
                                break;
                            }
                        }
                    }

                    // Position Rectangle Sequence selected, but coordinates missing
                    if (overlay['position'] === 'MotionTrackRect') {
                        if (overlay['position_inputs']['coords'] === '') {
                            errorArray.push({
                                ...errorArrElements(
                                    cut['id'],
                                    overlay['id'],
                                    overlay['name'],
                                    overlay['source']
                                ),
                                error: storyErrors.overlayRectSeqError,
                                error_id: STORY_ERRORS.OVERLAY_RECT_SEQUENCE_ERROR
                            });
                        }
                    }

                    // Position Corner Pin Sequence selected, but coordinates missing
                    if (overlay['position'] === 'MotionTrackQuad') {
                        if (overlay['position_inputs']['coords'] === '') {
                            errorArray.push({
                                ...errorArrElements(
                                    cut['id'],
                                    overlay['id'],
                                    overlay['name'],
                                    overlay['source']
                                ),
                                error: storyErrors.overlayCornerPinSeqError,
                                error_id: STORY_ERRORS.OVERLAY_CORNER_PIN_SEQUENCE_ERROR
                            });
                        }
                    }

                    // Asset ID attached to overlay, but asset has been deleted
                    if (overlay['source'] && overlay['source']['asset_id']) {
                        await api
                            .getAssetItem(overlay['source']['asset_id'])
                            .then((data: any) => data)
                            .catch((e: Error) => {
                                errorArray.push({
                                    ...errorArrElements(
                                        null,
                                        overlay['id'],
                                        overlay['name'],
                                        overlay['source']
                                    ),
                                    error: storyErrors.assetNotFound,
                                    error_id: STORY_ERRORS.OVERLAY_ASSET_NOT_FOUND
                                });
                            });
                    }
                }
            }
        }
    }
    return errorArray;
};

export const errorArrElements = (cut_id, overlay_id, name, source) => {
    return {
        cut_id,
        overlay_id,
        name,
        source
    };
};

export const findCutErrors = (cuts): any => {
    const errorArray = [];
    if (cuts) {
        for (const cut of cuts) {
            if ((cut.clipSwap && !cut['tags']) || (cut.clipSwap && cut['tags'].length === 0)) {
                errorArray.push({
                    ...errorArrElements(cut['id'], null, cut['name'], cut['source']),
                    error: storyErrors.clipSwapError,
                    error_id: STORY_ERRORS.CLIP_SWAP
                });
            }
        }
    }
    return errorArray;
};

export default story;
