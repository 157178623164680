import editor from './editor';

const timeline: any = {
    UPDATE: 'timeline/UPDATE',
    CLEAR: 'timeline/CLEAR'
};

export const updateTimelineState = (config, id?: string): any => {
    return (dispatch, getState) => {
        const { project } = getState();
        const compId = id ? id : project.compositionId;

        dispatch({ type: timeline.UPDATE, config, id: compId });

        if (
            config.activeTimelineLayer ||
            (config.activeMultiSelectLayers && config.activeMultiSelectLayers.length > 0)
        ) {
            dispatch({
                type: editor.UPDATE,
                config: {
                    expandPanel: false
                }
            });
        }
    };
};

export const selectKeyframe = (
    id: string,
    multiSelect: boolean = false,
    forceDeselect: boolean = false
): any => {
    return (dispatch, getState) => {
        const { project, timeline: timelineState } = getState();
        const compId = project.compositionId;
        const activeKeyframes = timelineState[compId].activeKeyframes;
        let newKeyframes = [];

        const inArray = activeKeyframes.includes(id);

        // If we're forcing deselect of all other keyframes, select only this keyframe
        // If the keyframe ID isn't in the array, and we're NOT multi-selecting, select only that keyframe
        if (forceDeselect || (!inArray && !multiSelect)) {
            newKeyframes = [id];

            // If the keyframe ID is already in array, and we're multi selecting, remove it
        } else if (inArray && multiSelect) {
            newKeyframes = activeKeyframes.filter((keyframeId) => keyframeId !== id);

            // If the keyframe ID isn't in the array, and we're multi selecting, add it
        } else if (!inArray && multiSelect) {
            newKeyframes = [...activeKeyframes];
            newKeyframes.push(id);
        } else {
            newKeyframes = [...activeKeyframes];
        }

        dispatch({ type: timeline.UPDATE, config: { activeKeyframes: newKeyframes }, id: compId });
    };
};

export const clearTimelineState = (id: string): any => {
    return (dispatch) => {
        dispatch({ type: timeline.CLEAR, id });
    };
};

export default timeline;
