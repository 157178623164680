import Timecode from 'smpte-timecode';
import { getStoryType, toFixed } from './story';
import { SCENE_TYPES } from '../constants/story';
import { header } from '../constants/copy';
import { POST_SETTINGS_ACTIONS, PROJECT_SETTINGS_TABS } from '../constants/editor';
import { VIEWER_TYPES } from '../constants/viewer';
import { updateEditorConfig } from '../redux/actions/editor';
import { updateTimelineState } from '../redux/actions/timeline';
import { addViewer, saveStory } from '../redux/actions/story';
import store from '../redux/store';
import { IConfirmModalProps } from '@imposium-hub/components';

export const fitToContainer = (container, content, config) => {
    let newH;
    let newW;
    let top;
    let left;
    const aspectRatio = content.width / content.height;
    const contWidth = config.padding ? container.width - config.padding * 2 : container.width;
    const contHeight = config.padding ? container.height - config.padding * 2 : container.height;
    const padding = config.padding ? config.padding : 0;
    // scale / letterbox
    if (config.scaleMode === 'proportionalInside') {
        newW = contWidth;
        newH = newW / aspectRatio;

        if (newH > contHeight) {
            newH = contHeight;
            newW = newH * aspectRatio;
        }
        // fill (crop)
    } else if (config.scaleMode === 'proportionalOutside') {
        newW = contWidth;
        newH = newW / aspectRatio;

        if (newH < contHeight) {
            newH = contHeight;
            newW = newH * aspectRatio;
        }
    } else if (config.scaleMode === 'none' || !config.scaleMode) {
        newW = content.width;
        newH = content.height;
    }

    if (config.maxWidth) {
        if (newW > config.maxWidth) {
            newW = config.maxWidth;
            newH = newW / aspectRatio;
        }
    }

    if (config.maxHeight) {
        if (newH > config.maxHeight) {
            newH = config.maxHeight;
            newW = newH * aspectRatio;
        }
    }

    // fit
    left =
        config.hAlign === 'left'
            ? 0
            : config.hAlign === 'right'
            ? contWidth - newW
            : (contWidth - newW) / 2;
    top =
        config.vAlign === 'top'
            ? 0
            : config.vAlign === 'bottom'
            ? contHeight - newH
            : (contHeight - newH) / 2;

    top += padding;
    left += padding;

    return {
        width: newW,
        height: newH,
        top,
        left
    };
};

export const getDuration = (frames, rates): string => {
    const rate = Math.ceil(rates);
    const frameRate = parseFloat(toFixed(rates, 3));

    let duration = new Timecode(frames, rate).toString();

    if (frameRate === 29.97) {
        duration = duration.replace(/:(?=[^:]*$)/, ';');
    }

    return duration.toString();
};

export const hasVideoOutputSettings = (actId, sceneId, story) => {
    const type = getStoryType(story);
    if (type === SCENE_TYPES.VIDEO || type === SCENE_TYPES.COMPOSITION) {
        const settings = story?.acts[actId]?.scenes[sceneId]?.sceneData?.encodingSettings;
        return (
            (settings && settings.length > 0) ||
            story?.acts[actId]?.scenes[sceneId]?.skipVideoOutput
        );
    } else {
        return true;
    }
};

export const previewStoryClicked = (e, inventoryOverrides?) => {
    e.preventDefault();
    e.stopPropagation();

    const {
        story,
        project: { actId, sceneId },
        changes: { unsaved }
    } = store.getState();

    const previewStory = () => {
        const hasSettings = hasVideoOutputSettings(actId, sceneId, story);

        if (hasSettings) {
            const type = getStoryType(story);

            if (type === SCENE_TYPES.COMPOSITION) {
                store.dispatch(
                    updateEditorConfig({
                        varsDisabled: true,
                        varsOpen: false,
                        inventoryOverrides,
                        autoRender: true
                    })
                );
                store.dispatch(
                    addViewer({
                        type: VIEWER_TYPES.COMPOSITION_PREVIEW,
                        label: `Composition Preview`,
                        id: VIEWER_TYPES.COMPOSITION_PREVIEW,
                        jobId: null
                    })
                );
            } else {
                store.dispatch(
                    addViewer({
                        type: VIEWER_TYPES.STORY_PREVIEW,
                        label: `Story Preview`,
                        id: VIEWER_TYPES.STORY_PREVIEW,
                        jobId: null
                    })
                );
            }
        } else {
            store.dispatch(
                updateEditorConfig({
                    settingsModalOpen: true,
                    postSettingsAction: POST_SETTINGS_ACTIONS.PREVIEW,
                    activeSettingsTab: PROJECT_SETTINGS_TABS.CONFIRM_SETTINGS
                })
            );
        }
    };

    if (unsaved) {
        const onYes = () => {
            store.dispatch(saveStory());
            previewStory();
        };

        openConfirmModal({
            showCancel: true,
            onYes: () => onYes(),
            yesLabel: header.onSave,
            onNo: () => previewStory(),
            noLabel: header.noSave,
            title: header.checkUnsaved
        });
    } else {
        previewStory();
    }
};

export const selectKeyframeAtPlayhead = (layer, activeFrame, keyframes) => {
    const startFrame = layer.start_frame;
    const relativeFrame = activeFrame - startFrame;

    const keyframe = keyframes.find((k) => {
        return k.relativeFrame === relativeFrame;
    });

    if (keyframe) {
        store.dispatch(
            updateTimelineState({
                activeKeyframes: [keyframe.id]
            })
        );
    }
};

export const capitalizeWord = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
};

export const formatColor = (color, hex?) => {
    const { r, g, b, a } = color.rgb;
    const alpha = color.source === 'hsv' ? 1 : a;

    if (hex) {
        return RGBAtoHexA(r, g, b, a);
    } else {
        return `rgba(${r},${g},${b},${alpha})`;
    }
};

export const RGBAtoHexA = (red, green, blue, alpha) => {
    let r = red.toString(16);
    let g = green.toString(16);
    let b = blue.toString(16);
    let a = Math.round(alpha * 255).toString(16);

    if (r.length === 1) r = '0' + r;
    if (g.length === 1) g = '0' + g;
    if (b.length === 1) b = '0' + b;
    if (a.length === 1) a = '0' + a;
    return '#' + r + g + b + a;
};

export const openConfirmModal = (detail: IConfirmModalProps) => {
    const event = new CustomEvent('openconfirmmodal', {
        detail
    });

    window.dispatchEvent(event);
};
