import actions from '../actions/timeline';

export interface ITimelineState {
    layerDragOffset: number;
    rightDragFrameOffset: number;
    leftDragFrameOffset: number;
    tempOffsetFrames: number;
    activeFrame: number;
    scaleModifier: number;
    positionOffset: number;
    activeTimelineCut: string;
    activeTimelineOverlay: string;
    activeTimelineLayer: string;
    activeKeyframes: string[];
    activeMultiSelectLayers: string[];
    expandedLayers: string[];
    playing: boolean;
    muted: boolean;
    volume: number;
}

const initialState: any = {};

const timeline = (state = initialState, action): any => {
    let newState: ITimelineState;
    switch (action.type) {
        case actions.UPDATE:
            const oldTimelineState = state[action.id] ? state[action.id] : {};
            const newTimelineObj = { ...oldTimelineState, ...action.config };
            const newTimelineState = { [action.id]: newTimelineObj };
            newState = { ...state, ...newTimelineState };
            return newState;
        case actions.CLEAR:
            newState = { ...state };
            delete newState[action.id];
            return newState;
        default:
            return state;
    }
};

export default timeline;
