import { api } from '../../constants/app';
import { MAX_DATASET_LENGTH } from '../../constants/dataset';
import { convertDatasetCSVStringToFile, formatDatasetToCSVFromArray } from '../../util/dataset';
import editorActions from './editor';
import * as Papa from 'papaparse';
import { dataEditor as copy } from '../../constants/copy';
import { openConfirmModal } from '../../util/ui';

let datasetUpdateDebounce: any;

const DATASET_SAVE_DEBOUNCE = 250;

export const datasets: any = {
    SET_DATASET_LIST: 'datasets/SET_DATASET_LIST',
    SET_ACTIVE_DATASET: 'datasets/SET_ACTIVE_DATASET',
    CLEAR_ACTIVE_DATASET: 'datasets/CLEAR_ACTIVE_DATASET',
    SET_ACTIVE_DATASET_DATA: 'datasets/SET_ACTIVE_DATASET_DATA',
    SET_ACTIVE_DATASET_NAME: 'datasets/SET_ACTIVE_DATASET_NAME',
    SET_ACTIVE_DATASET_COLUMN_WIDTHS: 'datasets/SET_ACTIVE_DATASET_COLUMN_WIDTHS',
    SET_LOADING: 'SET_LOADING',
    INSERT_DATASET: 'INSERT_DATASET',
    UPDATE_DATASET: 'UPDATE_DATASET',
    REMOVE_DATASET: 'REMOVE_DATASET',
    ADD_ROW: 'ADD_ROW',
    ADD_COLUMN: 'ADD_COLUMN',
    DELETE_ROWS: 'DELETE_ROWS',
    DELETE_COLUMNS: 'DELETE_COLUMNS',
    DUPLICATE_ROW: 'DUPLICATE_ROW',
    RESET: 'RESET',
    UPDATE_ACTIVE_DATASET: 'UPDATE_ACTIVE_DATASET'
};

// TODO: all error handling!
export const createDataset = (storyId, csv, name) => {
    return (dispatch) => {
        dispatch(setLoadingData(true));
        return api.createDataset(storyId, csv, name).then((resp) => {
            dispatch({ type: editorActions.UPDATE, config: { activeDatasetId: resp.id } });
            dispatch({ type: datasets.SET_ACTIVE_DATASET, dataset: resp });

            // Remove the data from the object we're inserting to the dataset list
            const modified = { ...resp };
            delete modified.data;

            dispatch({ type: datasets.INSERT_DATASET, dataset: modified });
            dispatch(setLoadingData(false));
        });
        // .catch((error) => assetError(error));
    };
};

export const clearActiveDataset = () => {
    return (dispatch) => {
        dispatch({ type: datasets.CLEAR_ACTIVE_DATASET });
    };
};

export const resetDatasets = () => {
    return (dispatch) => {
        dispatch({ type: datasets.RESET });
    };
};

export const getDatasets = (storyId, activeId = null) => {
    return (dispatch) => {
        return api.getDatasets(storyId).then((resp) => {
            dispatch({ type: datasets.SET_DATASET_LIST, datasetList: resp });
            let foundActive = false;
            if (activeId) {
                resp.map((d) => {
                    if (d.id === activeId) {
                        dispatch(getDataset(storyId, d.id));
                        foundActive = true;
                    }
                });
                if (!foundActive) {
                    dispatch({ type: editorActions.UPDATE, config: { activeDatasetId: null } });
                }
            }
        });
        // .catch((error) => assetError(error));
    };
};

export const getDataset = (storyId, datasetId) => {
    return (dispatch) => {
        dispatch(setLoadingData(true));
        return api.getDataset(storyId, datasetId).then((resp) => {
            dispatch(setLoadingData(false));
            dispatch({ type: datasets.SET_ACTIVE_DATASET, dataset: resp });
        });
        // .catch((error) => assetError(error));
    };
};

export const updateDatasetData = (storyId, datasetId, data, name) => {
    return (dispatch) => {
        dispatch({ type: datasets.SET_ACTIVE_DATASET_DATA, datasetId, data });
        dispatch(saveDataset(storyId, datasetId));
    };
};

export const addDatasetRow = (storyId, datasetId, index, rows) => {
    return (dispatch) => {
        dispatch({ type: datasets.ADD_ROW, datasetId, index, rows });
        dispatch(saveDataset(storyId, datasetId));
    };
};

export const duplicateDatasetRow = (storyId, datasetId, index) => {
    return (dispatch) => {
        dispatch({ type: datasets.DUPLICATE_ROW, datasetId, index });
        dispatch(saveDataset(storyId, datasetId));
    };
};

export const addDatasetColumn = (storyId, datasetId, columnHeader) => {
    return (dispatch) => {
        dispatch({ type: datasets.ADD_COLUMN, datasetId, columnHeader });
        dispatch(saveDataset(storyId, datasetId));
    };
};

export const deleteDatasetRows = (storyId, datasetId, range) => {
    return (dispatch) => {
        dispatch({ type: datasets.DELETE_ROWS, datasetId, range });
        dispatch(saveDataset(storyId, datasetId));
    };
};

export const deleteDatasetColumns = (storyId, datasetId, columns) => {
    return (dispatch) => {
        dispatch({ type: datasets.DELETE_COLUMNS, datasetId, columns });
        dispatch(saveDataset(storyId, datasetId));
    };
};

export const updateActiveDataset = (storyId, datasetId, dataset) => {
    return (dispatch) => {
        dispatch({ type: datasets.UPDATE_ACTIVE_DATASET, datasetId, dataset });
        dispatch(saveDataset(storyId, datasetId));
    };
};

export const saveDataset = (storyId, datasetId) => {
    return (dispatch, getState) => {
        clearTimeout(datasetUpdateDebounce);
        datasetUpdateDebounce = setTimeout(() => {
            const state = getState();
            const dataset = state.datasets.present.activeDataset;
            const data = formatDatasetToCSVFromArray(dataset);
            api.updateDataset(storyId, datasetId, data);
        }, DATASET_SAVE_DEBOUNCE);
        // .catch((error) => assetError(error));
    };
};

export const importDataset = (storyId, datasetId, file) => {
    return (dispatch) => {
        dispatch({ type: datasets.SET_LOADING, loading: true });

        const doUpload = (f) => {
            api.updateDataset(storyId, datasetId, f).then((resp) => {
                dispatch({ type: editorActions.UPDATE, config: { activeDatasetId: resp.id } });
                dispatch({ type: datasets.SET_ACTIVE_DATASET, dataset: resp });
                dispatch({ type: datasets.SET_LOADING, loading: false });
            });
        };

        // Parse the dataset, trim to 1k if it is over 1k
        Papa.parse(file, {
            complete: (results) => {
                if (results.data.length > MAX_DATASET_LENGTH + 1) {
                    // +1 to account for headers
                    const overBy = results.data.length - (MAX_DATASET_LENGTH + 1);

                    const onYes = () => {
                        const newData = results.data.slice(0, MAX_DATASET_LENGTH + 1);
                        const unparsed = Papa.unparse(newData, { skipEmptyLines: true });
                        const newFile = convertDatasetCSVStringToFile(unparsed, file.name);
                        doUpload(newFile);
                    };

                    const title = copy.datasetTooLong
                        .replaceAll('[max]', MAX_DATASET_LENGTH.toString())
                        .replaceAll('[overBy]', overBy.toString());

                    openConfirmModal({
                        onNo: () => dispatch({ type: datasets.SET_LOADING, loading: false }),
                        onYes: () => onYes(),
                        title
                    });
                } else {
                    doUpload(file);
                }
            }
        });
    };
};

export const updateDatasetName = (storyId, datasetId, name) => {
    return (dispatch) => {
        dispatch({ type: datasets.SET_ACTIVE_DATASET_NAME, datasetId, name });
        api.updateDataset(storyId, datasetId, null, name);
        // .catch((error) => assetError(error));
    };
};

export const updateDatasetColumnWidths = (storyId, datasetId, columnWidths) => {
    return (dispatch) => {
        dispatch({ type: datasets.SET_ACTIVE_DATASET_COLUMN_WIDTHS, datasetId, columnWidths });
        api.updateDataset(storyId, datasetId, null, null, columnWidths);
    };
};

export const setLoadingData = (loading) => {
    return (dispatch) => {
        dispatch({ type: datasets.SET_LOADING, loading });
    };
};

export const deleteDataset = (storyId, datasetId) => {
    return (dispatch) => {
        dispatch({ type: editorActions.UPDATE, config: { activeDatasetId: null } });
        dispatch({ type: datasets.CLEAR_ACTIVE_DATASET });
        dispatch({ type: datasets.REMOVE_DATASET, id: datasetId });
        return api.deleteDataset(storyId, datasetId);
    };
};

export const duplicateDataset = (storyId, dataset, newName) => {
    const data = formatDatasetToCSVFromArray(dataset);
    return (dispatch) => {
        return dispatch(createDataset(storyId, data, newName));
    };
};

export default datasets;
